import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyCode } from 'currency-codes-ts/dist/types';
import { EVENT_STATUS, EVENT_TYPE, LICENSE_TYPE, TICKET_STATUS } from '../constants/enums';
import { ApiErrorResponse } from '../types/ApiErrors';
import { EventResponse, EventRoutineData } from '../types/events';
import { Place } from '../types/places';
import { TicketResponse } from '../types/tickets';
import { BossUserInfo } from '../types/users';
import { api } from './api';

export interface EventSlice {
  id: string | null;
  boss: BossUserInfo | null;
  startDateTime: string | null;
  duration: number | null;
  routine: EventRoutineData | null;
  type: EVENT_TYPE | null;
  licenseType: LICENSE_TYPE | null;
  location: Place | null;
  currency: CurrencyCode;
  ticketPrice: number | null;
  capacity: number | null;
  messageToQueens?: string | undefined | null;
  status: EVENT_STATUS | null;
  numAttendees: number | null;
  tz: string;
  tickets: TicketResponse[];
}

export const initialState: EventSlice = {
  id: null,
  boss: null,
  startDateTime: null,
  duration: null,
  routine: null,
  type: null,
  licenseType: null,
  location: null,
  currency: 'GBP',
  ticketPrice: null,
  capacity: null,
  messageToQueens: null,
  status: null,
  numAttendees: null,
  tz: '',
  tickets: [],
};

const slice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    clearEventSlice(state) {
      return initialState;
    },

    setEvent(state, action: PayloadAction<EventResponse>) {
      state = Object.assign({}, state, action.payload);
      return state;
    },

    setTicketToCancelled(state, action: PayloadAction<{ ticketId: string }>) {
      const ticketIndex = state.tickets.findIndex(
        (ticket) => ticket.id === action.payload.ticketId,
      );
      state.tickets[ticketIndex].status = TICKET_STATUS.CANCELLED;
      state.numAttendees = state.numAttendees ? state.numAttendees - 1 : null;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getEventTickets.matchFulfilled, (state, { payload }) => {
      if (!(payload instanceof ApiErrorResponse)) {
        state.tickets = payload;
      }
      return state;
    });
  },
});

const { actions, reducer } = slice;
export const { clearEventSlice, setEvent, setTicketToCancelled } = actions;
export default reducer;
