import moment from 'moment-timezone';
import { EventResponse } from '../types/events';
import { capitalizeFirstLetter } from './string';

export function formatEventDuration(durationMins: number) {
  const hours = Math.floor(durationMins / 60);
  const mins = durationMins % 60;

  return `${hours === 1 ? `1 hour` : hours > 1 ? `${hours} hours` : ''}${
    mins > 0 ? ` ${mins} mins` : ''
  }`;
}

export function getEventDetailsString(event: EventResponse) {
  const startDate = new Date(event.startDateTime);

  const startTime = moment.tz(startDate, event.tz).format('h:mma');
  const endDateTime = moment.tz(startDate, event.tz).add(event.duration, 'm').toDate();
  const endTime = moment.tz(endDateTime, event.tz).format('h:mma');

  return `${capitalizeFirstLetter(event.type)} · ${startTime} - ${endTime} · ${formatEventDuration(
    event.duration,
  )} · ${event.ticketPrice?.toLocaleString('en-GB', {
    style: 'currency',
    currency: event.currency || 'GBP',
  })}`;
}
